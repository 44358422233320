import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useLocation, Link } from 'react-router-dom';
import { propEq, findIndex, omit } from 'ramda';
import moment from 'moment';
import { Formik, Form } from 'formik';
import * as yup from 'yup';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';

import Button from '../../../components/Button';

import { convertAmountToDB, convertDateToDB } from '../../../helpers/converters';
import { convertToMask } from '../../../components/Input/masks';
import { formatDate } from 'helpers/formatters';

import { setClientId, clearBasicInfo } from './steps/BasicInfo/store/actions';
import { selectClient } from 'pages/ClientSelection/store/actions';
import { setRestrictions } from './steps/Restrictions/store/actions';
import { setCharges, clearFrequency, clearCharges } from './steps/Charges/store/actions';
import { clearAutomations, setAutomations } from './steps/Automations/store/actions';
import { clearRestrictions } from './steps/Restrictions/store/actions';
import { setTypePricesLink } from './steps/LinkTable/store/actions';

import { loadFilters } from '../../../components/Filter/store/thunk';
import { upsertClient, loadClient } from './steps/BasicInfo/store/thunk';
import { upsertCharge } from './steps/Charges/store/thunk';
import { registerTypesPricesLink } from './steps/LinkTable/store/thunk';

import { validations as basicValidations } from './steps/BasicInfo/components/validations';
import { chargeValidations, validationNotInvoiceDate } from './steps/Charges/components/validations';
import { validations as restrictionsValidations } from './steps/Restrictions/components/validations';

import { BasicInfo, VehicleRegister, Automations, Restrictions, Charges, LinkTable, LinkServices } from './steps';

import { useStyles } from './styles';

export default function ClientRegistration() {
  const classes = useStyles();

  const [config, setConfig] = useState([]);

  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();

  const isEdit = history.location.state?.data?.isEdit || false;

  const establishmentId =
    useSelector(state => state.businessInfo.establishmentId) || history.location.state?.data?.establishmentId;

  const { establishmentTypes } = useSelector(state => state.businessInfo);
  const userId = useSelector(state => state.profile.userId);
  const clientTypeId = useSelector(state => state.clientSelection.clientTypeId);
  const { clientId, isLoading } = useSelector(state => state.clientRegistration.basicInfo);
  const basicInfoSubmit = useSelector(state => state.clientRegistration.basicInfo.submit);
  const automationsSubmit = useSelector(state => state.clientRegistration.automations.submit);
  const restrictionsSubmit = useSelector(state => state.clientRegistration.restrictions.submit);
  const chargeSubmit = useSelector(state => state.clientRegistration.charges.submit);
  const linkTableSubmit = useSelector(state => state.clientRegistration.linkTable.submit);
  const linkServiceSubmit = useSelector(state => state.clientRegistration.linkServices.submit);
  const { createdInvoiceDate } = useSelector(state => state.clientRegistration.charges);

  const [upsertClientParams, setUpsertClientParams] = useState({});

  const defaultEstablishmentTypes = establishmentTypes || [];

  const isStoreOnly = defaultEstablishmentTypes.length == 1 && +defaultEstablishmentTypes[0] == 3
  const isInvoiceConfigCreated = +chargeSubmit?.invoiceDueDay != 0;

  const linkServicesParamsConfiguration = {
    id: 'linkServices',
    title: 'Vincular serviço',
    path: '/cadastro-de-cliente/vincular-servico/',
    component: LinkServices,
    onSubmit: params => {
      const linkServicesParams = {
        servicesLink: params.servicesLink.length === 0 ? [""] : params.servicesLink,
        userId,
        establishmentId,
        clientId
      }

      return dispatch(registerTypesPricesLink(linkServicesParams));
    },
    submitParams: {
      ...linkServiceSubmit
    }
  }

  const linkTableParamsConfiguration = {
    id: 'linkTable',
    title: 'Vincular tabela',
    path: '/cadastro-de-cliente/vincular-tabela/',
    component: LinkTable,
    onSubmit: params => {
      const linkTableParams = {
        typePricesLink: params.typePriceLink.length === 0 ? [""] : params.typePriceLink,
        userId,
        establishmentId,
        clientId
      }

      dispatch(setTypePricesLink(linkTableParams.typePricesLink));
      return dispatch(registerTypesPricesLink(linkTableParams));
    },
    submitParams: {
      ...linkTableSubmit
    }
  }

  let basicInfoParamsConfiguration = {
    id: 'basicInfo',
    title: 'Informações básicas',
    path: '/cadastro-de-cliente/informacoes-basicas/',
    component: BasicInfo,
    onSubmit: params => {
      setUpsertClientParams(params);

      const formattedParams = {
        ...params,
        email: params?.email?.replace(/\s/g, '')
      }

      return dispatch(upsertClient(formattedParams, clientId));
    },
    validations: basicValidations,
    submitParams: {
      ...basicInfoSubmit,
      clientTypeId,
      userId,
      establishmentId
    }
  }

  const vehiclesParamsConfiguration = {
    id: 'vehicles',
    title: 'Cadastro de veículos',
    path: '/cadastro-de-cliente/cadastro-de-veiculos/',
    component: VehicleRegister,
    onSubmit: 'push',
    validations: yup.object().shape({}),
    submitParams: {}
  }

  const automationsParamsConfiguration = {
    id: 'automations',
    title: 'Automatizações',
    path: '/cadastro-de-cliente/automatizacoes/',
    component: Automations,
    onSubmit: params => {
      const automationsParams = {
        ...params,
        typePrices: params.typePrices.length === 0 ? [""] : params.typePrices,
        services: params.services.length === 0 ? [""] : params.services
      }

      return dispatch(upsertClient(automationsParams));
    },
    validations: yup.object().shape({}),
    submitParams: {
      userId,
      establishmentId,
      ...automationsSubmit
    }
  }

  const restrictionsConfiguration = {
    id: 'restrictions',
    title: 'Restrições',
    path: '/cadastro-de-cliente/restricoes/',
    component: Restrictions,
    onSubmit: params => {
      return dispatch(upsertClient(params));
    },
    validations: clientTypeId == 2 ? restrictionsValidations : null,
    submitParams: {
      userId,
      establishmentId,
      ...restrictionsSubmit
    }
  }

  const chargeConfiguration = {
    id: 'charges',
    title: 'Cobranças',
    path: '/cadastro-de-cliente/cobrancas/',
    component: Charges,
    validations: createdInvoiceDate ? chargeValidations : validationNotInvoiceDate,
    onSubmit: params => {
      const chargeParams = {
        ...params,
        contractDueDate:
          params.contractDueDate == 'Data inválida' ||
          !moment(params.contractDueDate).isValid()
            ? '0001-01-01' : params.contractDueDate,
        userId,
        establishmentId,
        clientId
      }

      return dispatch(upsertCharge(chargeParams));
    },
    submitParams: {
      isEdit,
      clientTypeId,
      establishmentId,
      ...chargeSubmit,
      frequencyId: !chargeSubmit.frequencyId ? 1 : chargeSubmit.frequencyId,
      invoiceDueDay:
        (chargeSubmit.frequencyId === 1 || !chargeSubmit.frequencyId) &&
        chargeSubmit.invoiceDueDay === 0
          ? 1
          : chargeSubmit.invoiceDueDay
    }
  }

  const handlePageConfig = clientTypeId => {
    if(clientTypeId === 1) {
      if(isStoreOnly) {
        return [
          basicInfoParamsConfiguration
        ];
      }

      return [
        basicInfoParamsConfiguration,
        vehiclesParamsConfiguration,
        automationsParamsConfiguration,
        restrictionsConfiguration,
      ];
    }

    if(clientTypeId === 2) {
      return [
        basicInfoParamsConfiguration,
        vehiclesParamsConfiguration,
        restrictionsConfiguration,
        chargeConfiguration,
      ];
    }

    if(clientTypeId === 4) {
      return [
        basicInfoParamsConfiguration,
        vehiclesParamsConfiguration,
        restrictionsConfiguration,
        chargeConfiguration,
        linkTableParamsConfiguration,
        automationsParamsConfiguration,
      ];
    }

    if(clientTypeId === 5) {
      return [
        basicInfoParamsConfiguration,
        vehiclesParamsConfiguration,
        chargeConfiguration,
        linkServicesParamsConfiguration,
        automationsParamsConfiguration,
      ];
    }

    return [
      basicInfoParamsConfiguration,
      vehiclesParamsConfiguration,
      restrictionsConfiguration,
      chargeConfiguration,
      automationsParamsConfiguration,
    ];
  }

  useEffect(() => {
    dispatch(loadFilters(userId, establishmentId));
    setConfig(handlePageConfig(clientTypeId));

    return () => {
      dispatch(setClientId(null));
      dispatch(clearBasicInfo());
      dispatch(clearFrequency(1));
      dispatch(clearAutomations());
      dispatch(clearRestrictions());
      dispatch(clearCharges());
    }
  }, []);

  useEffect(() => {
    if(clientId) {
      let clientParams;

      isEdit
        ? clientParams = history.location.state?.data
        : clientParams = upsertClientParams;

      const params = {
        ...clientParams,
        userId,
        establishmentId,
        clientId
      }

      dispatch(loadClient(params)).then(data => {
        const phone = data.phones ? data.phones[0] : [''];

        basicInfoParamsConfiguration = {
          ...basicInfoParamsConfiguration,
          submitParams: {
            ...basicInfoParamsConfiguration.submitParams,
            name: data.name,
            email: data.email,
            phones: data.phones,
            phone: data.phones ? convertToMask('phone', phone) : null,
            dateOfBirth: data.dateOfBirth == '0001-01-01' ? '' : formatDate(data.dateOfBirth),
            documentId: convertToMask('document', data.documentId),
            documentIdType: data.documentIdType,
            status: data.status,
            postalCode: data.postalCode,
            street: data.street,
            streetNumber: data.streetNumber,
            streetComplement: data.streetComplement,
            district: data.district,
            city: data.city,
            state: data.state,
            comments: data.comments
          }
        }

        dispatch(selectClient(data.clientTypeId));
        dispatch(setRestrictions(data));
        dispatch(setCharges(data));
        dispatch(setAutomations(data));

        setConfig(handlePageConfig(isEdit ? data.clientTypeId : clientTypeId));
      });
    }
  }, [clientId]);

  const pushStep = () => {
    const currentIndex = findIndex(propEq('path', location.pathname), config);
    const nextIndex = currentIndex + 1;

    if(config[nextIndex]) {
      history.push(config[nextIndex].path);
    } else {
      history.push('/clientes');
    }

    window.scrollTo(0, 0);
  }

  const handleToNextStep = async values => {
    const currentIndex = findIndex(propEq('path', location.pathname), config);
    const currentStep = config[currentIndex];
    const { onSubmit } = currentStep;

    if(onSubmit === 'push') {
      return pushStep();
    }

    const { amount, phone, dateOfBirth } = values;

    let formattedDateOfBirth = convertDateToDB(dateOfBirth);

    if(formattedDateOfBirth == 'Invalid date' || formattedDateOfBirth == 'Data inválida') {
      formattedDateOfBirth = dateOfBirth;
    }

    if(formattedDateOfBirth == '--') {
      formattedDateOfBirth = '';
    }

    let params = {
      ...values,
      clientTypeId,
      clientId,
      amount: amount ? convertAmountToDB(amount) : '',
      dateOfBirth: formattedDateOfBirth
    }

    if(currentStep.id === 'basicInfo') {
      params.phones = [phone];
    }

    if(currentStep.id !== 'charges') {
      params = omit(['amount'], params);
    }

    if(currentStep.id === 'linkServices') {
      const formattedServicesLink = params.servicesLink.filter(service => +service != 0);
      params.servicesLink = formattedServicesLink;
    }

    if(currentStep.id !== 'basicInfo') {
      params = omit(['name', 'dateOfBirth'], params);
    }

    return onSubmit(params).then(error => {
      if(params.status == 0) {
        return history.push('/clientes');
      }

      if(!error) {
        pushStep();
      }
    });
  }

  const handleToPreviousStep = () => {
    const prevIndex = findIndex(propEq('path', location.pathname), config) - 1;

    if(prevIndex < 0) {
      return history.push('/clientes');
    }

    return history.push(config[prevIndex].path);
  }

  const currentIndex = findIndex(propEq('path', location.pathname), config);
  const nextIndex = currentIndex + 1;

  return(
    <Container className={classes.root}>
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={currentIndex}
        style={{ background: 'transparent', overflowX: 'auto', paddingTop: 0 }}
      >
        {config.map((nav, i) => (
          <Step key={nav.path}>
            <StepButton
              completed={currentIndex > i}
              disabled={
                !clientId ||
                (!isInvoiceConfigCreated && (clientTypeId == 4) && (i > 3)) ||
                (!isInvoiceConfigCreated && (clientTypeId == 5) && (i > 2))
              }
              component={Link}
              to={nav.path}
              style={{ textDecoration: 'none' }}
            >
              {nav.title}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      {config[currentIndex]?.id == 'automations' && (
        <Grid
          container
          spacing={2}
          style={{
            width: 'auto',
            margin: '15px 0 15px 0',
            backgroundColor: '#f6ebe9',
            border: '1px solid #efd7d7',
            borderRadius: 10
          }}
        >
          <Grid item xs={12}>
            <Typography style={{ color: '#aa513d', margin: 5, fontWeight: 500 }}>
              Atenção: <br /><br />
              Toda vez que o cliente for carregado pela placa, cartão QRCode
              ou selecionado, este cliente poderá usar apenas a(s) tabela(s)
              selecionada(s) e/ou o(s) serviço(s) selecionado(s) abaixo.
            </Typography>
          </Grid>
        </Grid>
      )}
      {config[currentIndex]?.id == 'linkTable' && (
        <Grid
          container
          spacing={2}
          style={{
            width: 'auto',
            margin: '15px 0 15px 0',
            backgroundColor: '#f6ebe9',
            border: '1px solid #efd7d7',
            borderRadius: 10
          }}
        >
          <Grid item xs={12}>
            <Typography style={{ color: '#aa513d', margin: 5, fontWeight: 500 }}>
              Atenção: <br /><br />
              Ao vincular uma tabela no cliente, toda vez que ela for selecionada na
              operação vai ser carregado o cliente na Ordem de Serviço. Se isso não
              for planejado, vão ocorrer problemas operacionais.
            </Typography>
          </Grid>
        </Grid>
      )}
      {config[currentIndex]?.id == 'linkServices' && (
        <Grid
          container
          spacing={2}
          style={{
            width: 'auto',
            margin: '15px 0 15px 0',
            backgroundColor: '#f6ebe9',
            border: '1px solid #efd7d7',
            borderRadius: 10
          }}
        >
          <Grid item xs={12}>
            <Typography style={{ color: '#aa513d', margin: 5, fontWeight: 500 }}>
              Atenção: <br /><br />
              Ao vincular um serviço no cliente, toda vez que ele for selecionado na
              operação vai ser carregado o cliente na Ordem de Serviço. Se isso não
              for planejado, vão ocorrer problemas operacionais.
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid>
        {config.map(({ component: Component, ...navigationProps }) => (
          <Route
            exact
            key={navigationProps.path}
            path={navigationProps.path}
            render={routeProps => (
              <Formik
                enableReinitialize
                initialValues={navigationProps.submitParams}
                validationSchema={navigationProps.validations}
                onSubmit={handleToNextStep}
              >
                {({ ...formikProps }) => (
                  <Form>
                    <Component
                      formikInjectProps={formikProps}
                      {...routeProps}
                    />
                    <Grid className={classes.footer}>
                      <Container>
                        <Grid container justify="space-between">
                          <Button
                            type="button"
                            color="secondary"
                            loading={isLoading}
                            onClick={() => handleToPreviousStep()}
                          >
                            Voltar
                          </Button>
                          <Button color="success" loading={isLoading}>
                            {config[nextIndex] ? 'Avançar' : 'Finalizar cadastro'}
                          </Button>
                        </Grid>
                      </Container>
                    </Grid>
                  </Form>
                )}
              </Formik>
            )}
          />
        ))}
      </Grid>
    </Container>
  );
}