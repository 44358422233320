import React from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';

import useWindowDimensions from 'helpers/hooks/useWindowDimensions';

const ProvisionalReceiptMaintenanceSVG = require('../../assets/img/provisional_receipt_maintenance.svg');

export default function ProvisionalReceiptMaintenance() {
  const { width } = useWindowDimensions();
  const isMobile = width < 728;

  if(isMobile) {
    return(
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column', gap: 20, padding: 20 }}>
            <Typography variant='h5' color='primary' style={{ marginTop: 50 }}>Funcionalidade em manutenção.</Typography>
            <Typography color='subtitle1'>
              <b>Não se preocupe! Suas notas continuarão sendo emitidas e em caso de problema o suporte será dado.</b>
            </Typography>
            <Typography color='textSecondary'>Estamos atualizando nosso sistema de notas fiscais, o serviço para cadastro de notas permanecerá indisponível por até 30 dias.</Typography>
            <Typography variant='subtitle1'>
              <b>Previsão de retorno dia 30/10/2024</b>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <img src={ProvisionalReceiptMaintenanceSVG} style={{ width: '100%', height: 'auto', padding: 20 }} />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return(
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <img src={ProvisionalReceiptMaintenanceSVG} style={{ width: '100%', height: 'auto', padding: 20 }} />
        </Grid>
        <Grid item xs={12} sm={6} style={{ display: 'flex', flexDirection: 'column', gap: 20, padding: 20 }}>
          <Typography variant='h5' color='primary' style={{ marginTop: 50 }}>Funcionalidade em manutenção.</Typography>
          <Typography color='subtitle1'>
            <b>Não se preocupe! Suas notas continuarão sendo emitidas e em caso de problema o suporte será dado.</b>
          </Typography>
          <Typography color='textSecondary'>Estamos atualizando nosso sistema de notas fiscais, o serviço para cadastro de notas permanecerá indisponível por até 30 dias.</Typography>
          <Typography variant='subtitle1'>
            <b>Previsão de retorno dia 30/10/2024</b>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}