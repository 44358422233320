import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Input from '../../../../../../components/Input';
import Status from '../../../../../../components/Status';
import Loader from '../../../../../../components/Loader';
import SingleDatePicker from 'components/SingleDatePicker';

import { loadClient, loadAddressWithoutPersist } from '../store/thunk';

import { convertDateToDB } from 'helpers/converters';
import { formatDocument, formatPhone, formatDate } from '../../../../../../helpers/formatters';
import useWindowDimensions from '../../../../../../helpers/hooks/useWindowDimensions';

import { useStyles } from './styles';

export default function BasicInfo({ formikInjectProps }) {
  const dispatch = useDispatch();

  const classes = useStyles();
  const windowSize = useWindowDimensions();
  const orderFields = windowSize.width > 960 ? 'row' : 'column-reverse';

  const userId = useSelector(state => state.profile.userId);
  const establishmentId = useSelector(state => state.businessInfo.establishmentId);
  const { clientId } = useSelector(state => state.clientRegistration.basicInfo);

  const { isLoading, postalCodeState } = useSelector(state => state.clientRegistration.basicInfo);
  const clientData = useSelector(state => state.clientRegistration.basicInfo.submit);

  useEffect(() => {
    const params = {
      userId,
      establishmentId,
      clientId
    }

    if(clientId) {
      dispatch(loadClient(params));
    }
  }, []);

  useEffect(() => {
    const {
      name,
      status,
      email,
      phones,
      dateOfBirth,
      documentId,
      comments,
      postalCode,
      street,
      streetNumber,
      streetComplement,
      district,
      city,
      state
    } = clientData;

    formikInjectProps.setFieldValue('name', name);
    formikInjectProps.setFieldValue('status', status);
    formikInjectProps.setFieldValue('email', email);
    formikInjectProps.setFieldValue('phone', phones ? formatPhone(phones[0]) : '');
    formikInjectProps.setFieldValue('dateOfBirth', dateOfBirth == '0001-01-01' ? '' : formatDate(dateOfBirth));
    formikInjectProps.setFieldValue('documentId', formatDocument(documentId));
    formikInjectProps.setFieldValue('comments', comments);
    formikInjectProps.setFieldValue('postalCode', postalCode);
    formikInjectProps.setFieldValue('street', street);
    formikInjectProps.setFieldValue('streetNumber', streetNumber);
    formikInjectProps.setFieldValue('streetComplement', streetComplement);
    formikInjectProps.setFieldValue('district', district);
    formikInjectProps.setFieldValue('city', city);
    formikInjectProps.setFieldValue('state', state);
  }, [clientData]);

  const handlePostalCode = e => {
    const { name, value } = e.target;

    formikInjectProps.setFieldValue(name, value);

    if(value.length === 9) {
      return dispatch(loadAddressWithoutPersist(value)).then(data => {
        const { logradouro, bairro, localidade, uf } = data;
        formikInjectProps.setFieldValue('street', logradouro);
        formikInjectProps.setFieldValue('district', bairro);
        formikInjectProps.setFieldValue('city', localidade);
        formikInjectProps.setFieldValue('state', uf);
      });
    }
  }

  return(
    <Card className="mt-4">
      <CardHeader
        style={{ backgroundColor: '#1E5168', padding: '8px' }}
        title={
          <Typography gutterBottom variant="h6" className={classes.root}>
            Informações básicas do cliente
          </Typography>
        }
      />
      <Loader isLoading={isLoading}>
        <CardContent>
          <Grid container spacing={3} direction={orderFields}>
            <Grid item md={6} xs={12}>
              <Input
                autoFocus
                name="name"
                label="Nome"
                value={formikInjectProps.values.name}
                error={formikInjectProps.errors.name}
                helperText={formikInjectProps.errors.name}
                onChange={e => formikInjectProps.handleChange(e)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Status
                name="status"
                value={formikInjectProps.values.status}
                error={formikInjectProps.errors.status}
                helperText={formikInjectProps.errors.status}
                onChange={e => formikInjectProps.handleChange(e)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <Input
                name="email"
                label="Email"
                placeholder="usuario@exemplo.com"
                value={formikInjectProps.values.email}
                error={formikInjectProps.errors.email}
                helperText={formikInjectProps.errors.email}
                onChange={e => formikInjectProps.handleChange(e)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Input
                name="phone"
                type="phone"
                label="Telefone"
                placeholder="(00) 0000-0000"
                value={formikInjectProps.values.phone}
                error={formikInjectProps.errors.phone}
                helperText={formikInjectProps.errors.phone}
                onChange={e => formikInjectProps.handleChange(e)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <SingleDatePicker
                name="dateOfBirth"
                label="Data de nascimento"
                error={!!formikInjectProps.errors.dateOfBirth}
                helperText={formikInjectProps.errors.dateOfBirth}
                initialDate={
                  clientData?.dateOfBirth == '0001-01-01'
                    ? null
                    : clientData?.dateOfBirth || formikInjectProps.values.dateOfBirth
                }
                onDateChange={date => formikInjectProps.setFieldValue("dateOfBirth", convertDateToDB(date))}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Input
                name="documentId"
                type="document"
                label="CPF/CNPJ"
                placeholder="000.000.000-00"
                value={formikInjectProps.values.documentId}
                error={formikInjectProps.errors.documentId}
                helperText={formikInjectProps.errors.documentId}
                onChange={e => formikInjectProps.handleChange(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                name="comments"
                label="Observação"
                value={formikInjectProps.values.comments}
                error={formikInjectProps.errors.comments}
                helperText={formikInjectProps.errors.comments}
                onChange={e => formikInjectProps.handleChange(e)}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item md={3} xs={12}>
              <Input
                name="postalCode"
                type="postalCode"
                label="CEP"
                placeholder="00000-000"
                value={formikInjectProps.values.postalCode}
                error={formikInjectProps.errors.postalCode}
                helperText={formikInjectProps.errors.postalCode}
                onChange={e => handlePostalCode(e, formikInjectProps.setFieldValue)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Input
                name="street"
                type="text"
                label="Endereço"
                placeholder="Endereço"
                loading={postalCodeState.isLoading}
                value={formikInjectProps.values.street}
                error={formikInjectProps.errors.street}
                helperText={formikInjectProps.errors.street}
                onChange={e => formikInjectProps.handleChange(e)}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Input
                name="streetNumber"
                type="text"
                label="Número"
                placeholder="0"
                loading={postalCodeState.isLoading}
                value={formikInjectProps.values.streetNumber}
                error={formikInjectProps.errors.streetNumber}
                helperText={formikInjectProps.errors.streetNumber}
                onChange={e => formikInjectProps.handleChange(e)}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Input
                name="streetComplement"
                type="text"
                label="Complemento"
                placeholder="Ex.: 3˚ andar"
                loading={postalCodeState.isLoading}
                value={formikInjectProps.values.streetComplement}
                error={formikInjectProps.errors.streetComplement}
                helperText={formikInjectProps.errors.streetComplement}
                onChange={e => formikInjectProps.handleChange(e)}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Input
                name="district"
                type="text"
                label="Bairro"
                placeholder="Bairro"
                loading={postalCodeState.isLoading}
                value={formikInjectProps.values.district}
                error={formikInjectProps.errors.district}
                helperText={formikInjectProps.errors.district}
                onChange={e => formikInjectProps.handleChange(e)}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Input
                name="city"
                type="text"
                label="Cidade"
                placeholder="Cidade"
                loading={postalCodeState.isLoading}
                value={formikInjectProps.values.city}
                error={formikInjectProps.errors.city}
                helperText={formikInjectProps.errors.city}
                onChange={e => formikInjectProps.handleChange(e)}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Input
                name="state"
                type="text"
                label="UF"
                placeholder="UF"
                loading={postalCodeState.isLoading}
                value={formikInjectProps.values.state}
                error={formikInjectProps.errors.state}
                helperText={formikInjectProps.errors.state}
                onChange={e => formikInjectProps.handleChange(e)}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Loader>
    </Card>
  );
}

BasicInfo.defaultProps = {
  formikInjectProps: {
    handleChange: () => {},
    setFieldValue: () => {},
    values: {
      name: '',
      email: '',
      phones: [],
      dateOfBirth: '',
      postalCode: '',
      street: '',
      streetNumber: 0,
      streetComplement: '',
      district: '',
      city: '',
      state: ''
    },
    errors: {
      name: false,
      email: false,
      phones: false,
      dateOfBirth: false,
      postalCode: false,
      street: false,
      streetNumber: false,
      streetComplement: false,
      district: false,
      city: false,
      state: false
    }
  }
};

BasicInfo.propTypes = {
  formikInjectProps: PropTypes.shape()
};