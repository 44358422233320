import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { pdf } from '@react-pdf/renderer';
import { toast } from 'react-toastify';
import { concat } from 'ramda';

import useDidMount from 'helpers/hooks/useDidMount';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faFileAlt, faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons';

import { persistQueryParamsSearch } from 'components/Filter/store/actions';
import { requestLoadClients, requestClientExportation } from '../store/thunk';
import { selectClient } from '../../ClientSelection/store/actions';
import { setClientId, setIsEdit } from '../../ClientRegistration/components/steps/BasicInfo/store/actions';
import { setEstablishmentId } from '../../BusinessInfo/store/actions';
import { setInvoiceId, handleUpdateInvoice } from '../../ClientInvoices/store/actions';

import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import MTable from '../../../components/MTable';
import Filter from '../../../components/Filter/components';
import FilterMobile from '../../../components/FilterMobile/components';
import Invoice from '../../../components/Invoice';
import InvoicePos from '../../../components/InvoicePos';
import { List } from '../../../components/ExportPDF/templates';
import { formatFilters, formatterClients } from '../../../helpers/formatters';
import { convertList, convertToEstablishmentName } from '../../../helpers/converters';
import { createPDF } from '../../../helpers/common';
import { setBaseAPI } from '../../../services';
import { inputs } from './resources';

import { useStyles } from './styles';

import { SITE_RESTRICTIONS } from '../../../helpers/restrictions';

const headers = [
  { title: 'Nome', field: 'name', sortable: false },
  { title: 'E-mail', field: 'email', sortable: false },
  { title: 'Telefone', field: 'phones', sortable: false },
  { title: 'Situação da fatura', field: 'clientInvoiceSituation', sortable: false },
  { title: 'Data de vencimento', field: 'dueDateTime', sortable: false }
];

const exportHeaders = [
  { label: 'Nome', id: 'name' },
  { label: 'E-mail', id: 'email' },
  { label: 'Telefone', id: 'phones' },
  { label: 'Situação da fatura', id: 'clientInvoiceSituation' },
  { label: 'Data de vencimento', id: 'dueDateTime' },
  { label: 'Valor da fatura', id: 'invoiceAmount' },
  { label: 'Veículos', id: 'vehicles' },
  { label: 'Vagas', id: 'vacancies' }
];

const width = window.innerWidth;
const isMobile = width < 1024;
const tableEditActionWidth = width < 768 ? 100 : 80;

export default function ClientList() {
  const dispatch = useDispatch();

  const history = useHistory();

  const classes = useStyles();

  const didComponentMount = useDidMount();

  const { userId } = useSelector(state => state.profile);
  const { establishmentId, establishmentTypes } = useSelector(state => state.businessInfo);
  const { establishments } = useSelector(state => state.login);
  const isUpdatingInvoice = useSelector(state => state.clientInvoices.isUpdatingInvoice);
  const { clients: fetchClients, total, isLoading } = useSelector(state => state.clientList);
  const { selects, qp, isFilterButtonClicked, isClientExportationError } = useSelector(state => state.filters);
  const { startDate, endDate } = useSelector(state => state.dateFilter);
  const userRestrictions = useSelector(state => state.profile.userRestrictions);

  const [selectedInvoiceEstablishmentId, setSelectedInvoiceEstablishmentId] = useState(null);
  const [isClientExportationLoading, setIsClientExportationLoading] = useState(false);

  const defaultEstablishmentTypes = establishmentTypes || [];

  const isCarWashOnly = defaultEstablishmentTypes.length == 1 && +defaultEstablishmentTypes[0] == 2;

  const establishmentName =
    convertToEstablishmentName(establishmentId, establishments) ||
    'Todos os estabelecimentos';

  const dateRange = [startDate, endDate];

  useEffect(() => {
    if(isClientExportationError) {
      setIsClientExportationLoading(true);

      handleClientExportation(userId, establishmentId, qp)
        .then(res => {
          const exportData = convertList(
            ['category', 'clientInvoiceSituation'],
            formatFilters(['category', 'clientInvoiceSituation'], selects),
            formatterClients(res?.content)
          );

          const component = pdf(
            <List
              exportResume={null}
              exportHeaders={establishmentId ? exportHeaders : [{ label: 'Estabelecimento', id: 'establishmentName' }, ...exportHeaders]}
              exportData={exportData}
              dateRange={dateRange}
              fileName="clientes"
              establishmentName={establishmentName}
            />
          );

          return createPDF(component);
        })
        .catch(() => toast.error('Não foi possível exportar, tente novamente mais tarde'))
        .finally(() => setIsClientExportationLoading(false));
    }
  }, [isClientExportationError]);

  const clientTypeFilterValue = () => {
    if(isCarWashOnly) {
      return [
        {
          label: '--',
          value: ''
        },
        {
          label: 'Cliente avulso',
          value: 1
        },
        {
          label: 'Cliente pós pago',
          value: 5
        },
      ];
    }

    return [
      {
        label: '--',
        value: ''
      },
      {
        label: 'Cliente avulso',
        value: 1
      },
      {
        label: 'Cliente com fatura fixa',
        value: 2
      },
      {
        label: 'Cliente pós pago',
        value: 4
      }
    ];
  }

  const filters = concat(
    formatFilters(['clientType', 'clientInvoiceSituation', 'status', 'card'], selects), inputs)
    .map(filter => {
      return filter?.id == 'clientType'
        ? {
          ...filter,
          options: clientTypeFilterValue()
        }
        : filter
    });

  const clients = convertList(['clientInvoiceSituation'], filters, fetchClients);

  const [isOpenInvoicePos, setIsOpenInvoicePos] = useState(false);

  const [clientsPage, setClientsPage] = useState(1);
  const clientsPageSize = 10;
  const clientsPageCount = Math.ceil(total / clientsPageSize);

  const handleLoadClients = ({ page, qp }) => {
    return dispatch(
      requestLoadClients({ userId, establishmentId, page, pageSize: clientsPageSize, qp })
    );
  }

  const handleClientExportation = (userId, establishmentId, qp) => {
    return dispatch(requestClientExportation(userId, establishmentId, qp));
  }

  useEffect(() => {
    dispatch(persistQueryParamsSearch(''));
  }, []);

  useEffect(() => {
    if(didComponentMount) {
      handleLoadClients({ page: 1, qp: '' });
    }
  }, [establishmentId]);

  useEffect(() => {
    if(!didComponentMount && !isFilterButtonClicked) {
      handleLoadClients({ page: clientsPage, qp: qp == '' ? '' : qp });
    }
  }, [qp]);

  useEffect(() => {
    if(!didComponentMount) {
      handleLoadClients({ page: clientsPage, qp: qp == '' ? '' : qp });
    }
  }, [clientsPage, establishmentId]);

  useEffect(() => {
    if(establishmentId) {
      handleLoadClients({ page: clientsPage, qp: qp == '' ? '' : qp });
    }
  }, [establishmentId]);

  const handleGoToEdit = client => {
    const { clientTypeId, clientId, establishmentId } = client;

    dispatch(setIsEdit(true));
    dispatch(selectClient(clientTypeId));
    dispatch(setClientId(clientId));
    dispatch(setEstablishmentId({ establishmentId }));
    setBaseAPI(userId, establishmentId);

    history.push({
      pathname: '/cadastro-de-cliente/informacoes-basicas/',
      state: {
        data: {
          userId,
          establishmentId,
          clientId,
          isEdit: true
        }
      }
    });
  }

  const handleGoToInvoices = async client => {
    const { clientId, establishmentId } = client;

    await dispatch(setClientId(clientId));
    await dispatch(setEstablishmentId({ establishmentId }));
    await setBaseAPI(userId, establishmentId);
    await history.push('/faturas');
  }

  const handleGoToLastInvoice = async client => {
    const { clientId, establishmentId, invoiceDateTime } = client;

    if(!invoiceDateTime) {
      return toast.error('Nenhuma fatura encontrada.');
    }

    setSelectedInvoiceEstablishmentId(establishmentId);

    await dispatch(setClientId(clientId));
    await dispatch(setInvoiceId(invoiceDateTime));
    await setBaseAPI(userId, establishmentId);

    if(client.clientTypeId === 4 || client.clientTypeId === 5) {
      setIsOpenInvoicePos(true);
      return;
    }

    await dispatch(handleUpdateInvoice(true));
  }

  const checkClientType = row => {
    const styles = { backgroundColor: 'none' };

    if(row) {
      const { clientTypeId } = row;

      if(clientTypeId === 1) {
        styles.backgroundColor = '#f8f9fa';
      }
    }

    return styles;
  }

  const verifyTypeClient = rowData => {
    if(rowData.clientTypeId === 5) {
      return false;
    }

    if(rowData.clientTypeId === 4) {
      return false;
    }

    if(rowData.clientTypeId === 2) {
      return false;
    }

    return true;
  }

  return (
    <Paper>
      {isMobile ? (
        <FilterMobile
          filter
          alternativeExport
          functionAlternativeExport={() => handleClientExportation(userId, establishmentId, qp)}
          hasSearchTerm
          hasLinkButton={!userRestrictions?.includes(SITE_RESTRICTIONS.CREATE_CLIENT) && "/tipos-de-cliente"}
          linkButtonText="Adicionar cliente"
          filters={filters}
          handlePageRequest={handleLoadClients}
          exportType="clients"
          fileName="clientes"
          exportHeaders={establishmentId ? exportHeaders : [{ label: 'Estabelecimento', id: 'establishmentName' }, ...exportHeaders]}
          formatComponentData={formatterClients}
        />
      ) : (
        <Filter
          filter
          alternativeExport
          functionAlternativeExport={() => handleClientExportation(userId, establishmentId, qp)}
          hasSearchTerm
          hasLinkButton={!userRestrictions?.includes(SITE_RESTRICTIONS.CREATE_CLIENT) && "/tipos-de-cliente"}
          linkButtonText="Adicionar cliente"
          filters={filters}
          handlePageRequest={handleLoadClients}
          exportType="clients"
          fileName="clientes"
          exportHeaders={establishmentId ? exportHeaders : [{ label: 'Estabelecimento', id: 'establishmentName' }, ...exportHeaders]}
          formatComponentData={formatterClients}
        />
      )}
      <div className={classes.table}>
        <MTable
          loading={isLoading || isClientExportationLoading}
          headers={headers}
          data={clients}
          actions={[
            rowData => ({
              icon: () => (
                <FontAwesomeIcon
                  icon={faFileInvoiceDollar}
                  color="#2CA9D6"
                  size="xs"
                />
              ),
              tooltip: 'Fatura pendente',
              onClick: (event, rowData) => handleGoToLastInvoice(rowData),
              hidden: verifyTypeClient(rowData)
            }),
            rowData => ({
              icon: () => (
                <FontAwesomeIcon icon={faFileAlt} color="#2CA9D6" size="xs" />
              ),
              tooltip: 'Lista de faturas',
              onClick: (event, rowData) => handleGoToInvoices(rowData),
              hidden: verifyTypeClient(rowData)
            }),
            !userRestrictions?.includes(SITE_RESTRICTIONS.EDIT_CLIENT) && {
              icon: () => (
                <FontAwesomeIcon icon={faPen} color="#2CA9D6" size="xs" />
              ),
              tooltip: 'Editar cliente',
              onClick: (event, rowData) => handleGoToEdit(rowData)
            }
          ]}
          actionColumnWidth={tableEditActionWidth}
          rowStyle={checkClientType}
        />
      </div>
      <Grid
        container
        xs={12}
        style={{ padding: 10, display: 'flex', justifyContent: 'flex-end' }}
      >
        <Pagination
          color="primary"
          variant="outlined"
          shape="rounded"
          count={clientsPageCount}
          page={clientsPage}
          onChange={(_, page) => setClientsPage(page)}
        />
      </Grid>
      {isUpdatingInvoice && (
        <Invoice
          isOpen={isUpdatingInvoice}
          establishmentId={selectedInvoiceEstablishmentId}
          pageOrigin="clients"
        />
      )}
      {isOpenInvoicePos && (
        <InvoicePos
          isOpen={isOpenInvoicePos}
          setIsOpenInvoicePos={setIsOpenInvoicePos}
          establishmentId={selectedInvoiceEstablishmentId}
        />
      )}
    </Paper>
  );
}